/* eslint-disable no-useless-escape */
import Joi from 'joi-browser';
import { add, format } from 'date-fns';

// For SignIn:
const schemaSignIn = {
  phone: Joi.string().required(),
  password: Joi.string()
    .min(6)
    .max(30)
    .regex(/^(?=.*\d).{6,30}$/)
    .required(),
};

// For registering a new user:
const schemaRegisterNewUser = {
  title: Joi.string().min(1).required(),
  firstname: Joi.string().min(2, 'utf8').max(30, 'utf8').required(),
  lastname: Joi.string().min(2, 'utf8').max(30, 'utf8').required(),
  email: Joi.string().email({ minDomainAtoms: 2 }).required(),
  phone: Joi.string().min(10).max(20).required(),
  password: Joi.string()
    .min(8)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$/)
    .max(30)
    .required(),
  passwordCheck: Joi.string()
    .min(8)
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,30}$/)
    .max(30)
    .required(),
  pobCity: Joi.string().min(1).max(60).required(),
  birthdate: Joi.object().required(),
  pobCountry: Joi.string().min(2).required(),
  nationality: Joi.string().min(2).required(),
};

const schemaStepCompanyInfo = {
  companyName: Joi.string().min(2).max(60).required(),
  legalRegNumber: Joi.string().min(2).max(60).required(),
  companyLegalName: Joi.string().min(2).max(60).required(),
  businessAddress: Joi.string().min(2).max(60).required(),
  city: Joi.string().min(2).max(30).required(),
  zipCode: Joi.string().min(2).max(30).required(),
  country: Joi.string().min(2).max(30).required(),
  headcount: Joi.string().min(1).max(30).required(),
  prevYearRevenue: Joi.string().min(1).max(30).regex(/^\d+$/).required(),
};

const schemaStepBusinessDetails = {
  businessType: Joi.string().min(2).max(60).required(),
  legalSector: Joi.string().min(2).max(60).required(),
  incorporationDate: Joi.any(),
  incorporationCountry: Joi.string().min(2).max(60).required(),
  companyActivity: Joi.string().min(0).max(250).allow('').optional(),
};

const schemaStepBusinessOwnership = {
  title: Joi.string().min(1, 'utf8').max(2, 'utf8').required(),
  email: Joi.string().email({ minDomainAtoms: 2 }).required(),
  firstname: Joi.string().min(2).max(60).required(),
  lastname: Joi.string().min(2).max(60).required(),
  pobCountry: Joi.string().min(2).required(),
  pobCity: Joi.string().min(1).max(60).required(),
  nationality: Joi.string().min(2).required(),
  name: Joi.any(),
  birthdate: Joi.object().required(),
  isUSPerson: Joi.boolean().required(),
  isBusiness: Joi.boolean(),
  twentyFivePercentOwners: Joi.any(),
  owners: Joi.array(),
};

const schemaStepReview = {
  legalAgreement: Joi.boolean().required(),
  privacyPolicyAgreement: Joi.boolean().required(),
  cardAgreement: Joi.boolean().required(),
};

// For InviteYourTeam
const inviteYourTeam = {
  email: Joi.string().email({ minDomainAtoms: 2 }).required(),
};

// For the Waiting List
const schemaWaitingList = {
  firstname: Joi.string().min(2, 'utf8').max(30, 'utf8').required(),
  lastname: Joi.string().min(2, 'utf8').max(30, 'utf8').required(),
  email: Joi.string().email({ minDomainAtoms: 2 }).required(),
  phone: Joi.string().min(10).max(20).required(),
  company: Joi.string().min(2).max(60).required(),
};

const schemaUpdateUserInfo = Joi.object({
  firstName: Joi.string().min(2, 'utf8').required(),
  lastName: Joi.string().min(2, 'utf8').required(),
  birthDate: Joi.date().max(format(add(new Date(), { years: -18 }), 'yyyy-MM-dd')),
});

const schemaRemoveAmlCftAlert = Joi.object({
  alertRemovalReason: Joi.string().min(4, 'utf8').required(),
});

export {
  schemaSignIn,
  schemaRegisterNewUser,
  schemaStepCompanyInfo,
  schemaStepBusinessDetails,
  schemaStepBusinessOwnership,
  schemaStepReview,
  inviteYourTeam,
  schemaWaitingList,
  schemaUpdateUserInfo,
  schemaRemoveAmlCftAlert,
};
