import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import notchedOutline from '@material-ui/core/OutlinedInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  CircularProgress,
  Grid,
  TextField,
  MenuItem,
  Button,
  Select,
  ListItemText,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  Chip,
} from '@material-ui/core';
import download from 'downloadjs';
import { useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { formatDate, getDateHour } from '../../utils';

import RelatedPaymentsContainer from './RelatedPaymentsContainer';

import { FormattedMessage, injectIntl } from 'react-intl';

import { toast } from 'react-toastify';

import { Query } from '@apollo/client/react/components';

import { FilterContext } from '../../FilterContext';

import GET_ALL_CREDITS from './GetAllCredits.gql';
import GET_ALL_CREDITS_BY_BORROWER from './GetAllCreditsByBorrowerId.gql';
import EXPORT_ALL_CREDITS from './ExportAllCredits.gql';

import { formatLoans } from '../formatLoans';
import { useDebounce } from '../../../../hooks/useDebounce';
import { Search } from '@material-ui/icons';

const DEFAULT_ROWS_PER_PAGE = 10;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const formatLoanType = type => {
  if (type === 'DJANGO_LATER_CLASSICAL') return 'Transaction';
  if (type === 'DJANGO_LATER_FREE_AMOUNT') return 'Montant libre';
  return '';
};

const headCells = [
  { id: 'state', numeric: false, disablePadding: false, label: 'Statut' },
  {
    id: 'startedAt',
    numeric: false,
    disablePadding: false,
    label: 'Commencé le',
  },
  {
    id: 'username',
    numeric: false,
    disablePadding: false,
    label: 'Utilisateur',
  },
  { id: 'loanId', numeric: false, disablePadding: false, label: 'ID du prêt' },
  {
    id: 'transactionAmount',
    numeric: true,
    disablePadding: false,
    label: 'Montant (hors frais)',
  },
  { id: 'feeAmount', numeric: true, disablePadding: false, label: 'Montant des frais' },
  { id: 'taeg', numeric: false, disablePadding: false, label: 'TAEG' },
  { id: 'loanType', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'rejectedReason', numeric: false, disablePadding: false, label: 'Raison du Rejet' },
  { id: 'bankName', numeric: false, disablePadding: false, label: 'Banque' },
  {
    id: 'bankAccountName',
    numeric: false,
    disablePadding: false,
    label: 'Compte Bancaire',
  },
];

const loanState = [
  { label: 'échoué', value: 'FAILED' },
  { label: 'rejeté', value: 'REJECTED' },
  { label: 'en cours', value: 'ONGOING' },
  { label: 'payé', value: 'DONE' },
];
const creditRejectedReason = [
  { label: 'FICHE FICP', value: 'REGISTERED' },
  { label: 'HOMONYMIE FICP', value: 'HOMONYMY' },
  { label: 'ECHEC APPEL FICP', value: 'CALL_FAILED' },
  { label: 'EN ATTENTE FICP', value: 'ON_HOLD' },
  { label: 'AUTRE', value: 'OTHER' },
];

export const formatCreditRejectedReason = status => {
  if (status != null) {
    const normalizedRejectedReason = status.toUpperCase();
    const match = creditRejectedReason.find(reason => normalizedRejectedReason.includes(reason.value));
    return match?.label || '';
  }

  return '';
};
const formatLoanStatus = status => {
  const state = loanState.find(s => s.value === status);
  return state ? state.label : '';
};

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    marginRight: 20,
    width: '30%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { filter, setFilter, status, searchId, opts, intl } = props;

  const [exportAllCredits] = useLazyQuery(EXPORT_ALL_CREDITS, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      if (response?.exportAllCredits) {
        const filename = response.exportAllCredits?.filename;
        const file = response.exportAllCredits?.file;
        download(`data:plain/csv;base64,${file}`, filename, 'text/plain');
        toast.success(intl.formatMessage({ id: 'components.admin.success' }), {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
        });
      }
    },
  });

  return (
    <Toolbar className={clsx(classes.root)}>
      <Tooltip title="Filtre">
        <IconButton aria-label="Filtre" onClick={() => setFilter(!filter)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        <FormattedMessage id="components.utils.loans" />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        style={{ marginRight: 5 }}
        onClick={() => {
          exportAllCredits({
            variables: {
              status: {
                included: status ?? [],
                excluded: [],
              },
              borrowerId: searchId,
            },
          });
        }}
      >
        EXPORT
      </Button>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cssLabel: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  filterInput: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
    minWidth: '100px',
  },
  selectFilter: {
    height: '55.97px',
  },
  searchFilterInput: {
    flex: 1,
    minWidth: '300px',
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    marginRight: '8px',
  },
  alignEnd: {
    marginLeft: 'auto',
  },
}));

const LoansContainer = ({ intl }) => {
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('startedAt');
  const [selected, setSelected] = useState(null);
  const [selectedBorrowerId, setSelectedBorrowerId] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [loans, setLoans] = useState([]);
  const [status, setStatus] = useState([]);
  const [searchId, setSearchId] = useState(null);

  const { filter, setFilter, userName } = useContext(FilterContext);
  const debouncedSearchId = useDebounce(searchId, 1000);

  useEffect(() => {
    setPage(1);
  }, [searchId]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, credit) => {
    setSelected(prevSelected => (prevSelected === credit.id ? null : credit.id));
    setSelectedBorrowerId(credit.borrower.id);
  };

  const isSelected = id => selected === id;

  const handleChangePage = (event, newPage) => {
    newPage += 1;
    setSelected(null);
    setSelectedBorrowerId(null);
    setPage(newPage < 1 ? 1 : newPage);
  };

  const handleChangeRowsPerPage = event => {
    setSelected(null);
    setSelectedBorrowerId(null);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const arrangeStateFilter = arr => {
    setSelected(null);
    setSelectedBorrowerId(null);
    const emptyStringIndex = arr.indexOf('');
    if (emptyStringIndex > -1) {
      arr.splice(emptyStringIndex, 1);
      arr.unshift('');
    }
    return arr;
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);
  const fields = headCells.map(cell => cell.id);
  const opts = { fields };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {filter && (
          <Grid container flexDirection="row" justify="flex-start">
            <FormControl variant="outlined" className={classes.filterInput}>
              <InputLabel
                classes={{
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                }}
                id="select-state-label"
              >
                <FormattedMessage id="components.utils.statut" />
              </InputLabel>
              <Select
                multiple
                name="state"
                labelId="select-state-label"
                value={status}
                onChange={e => {
                  setStatus(arrangeStateFilter(e.target.value));
                }}
                className={classes.selectFilter}
                input={<OutlinedInput id="select-multiple-chip" label="Selectionnez un statut" />}
                renderValue={selected => (
                  <Box className={classes.chips}>
                    {selected.map(value => {
                      const selectedOption = loanState.find(option => option.value === value);
                      return (
                        <Chip
                          key={value}
                          label={selectedOption ? selectedOption.label : value}
                          className={classes.chip}
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {loanState.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={status.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid item className={classes.alignEnd}>
              <div className={classes.searchContainer}>
                <TextField
                  name="searchCreditByUserId"
                  className={classes.searchFilterInput}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                    },
                    startAdornment: <Search className={classes.searchIcon} />,
                  }}
                  label={<FormattedMessage id="components.placeholder.searchCreditByUserId" />}
                  variant="outlined"
                  id="custom-css-outlined-input"
                  value={searchId || ''}
                  onChange={e => setSearchId(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        )}
        <Query
          query={debouncedSearchId ? GET_ALL_CREDITS_BY_BORROWER : GET_ALL_CREDITS}
          fetchPolicy="cache-and-network"
          variables={{
            status: {
              included: status ? status : [],
              excluded: [],
            },
            pagination: {
              page: page,
              pageLimit: rowsPerPage,
            },
            ...(debouncedSearchId && { borrowerId: debouncedSearchId }),
          }}
        >
          {({ data, error, loading, refetch }) => {
            if (loading) return <CircularProgress />;
            if (error) {
              if (error.networkError) {
                toast.error(intl.formatMessage({ id: 'components.utils.networkError' }), {
                  position: 'bottom-center',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: false,
                });
              } else {
                toast.error(
                  intl.formatMessage({
                    id: 'components.utils.errorHasOccured',
                  }) + error.message,
                  {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                  },
                );
              }
              return '';
            }

            if (data?.getAllCredits?.credits) setLoans(data.getAllCredits.credits);
            if (data?.getAllCreditsByBorrowerId?.credits) setLoans(data.getAllCreditsByBorrowerId.credits);
            if (data?.getAllCredits?.metadata?.totalItems) setCount(data.getAllCredits.metadata.totalItems);
            if (data?.getAllCreditsByBorrowerId?.metadata?.totalItems)
              setCount(data.getAllCreditsByBorrowerId.metadata.totalItems);
            return (
              <>
                <EnhancedTableToolbar
                  filter={filter}
                  setFilter={setFilter}
                  loans={loans}
                  refetch={refetch}
                  status={status}
                  searchId={debouncedSearchId}
                  userName={userName || null}
                  opts={opts}
                  intl={intl}
                />
                <TableContainer>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {stableSort(formatLoans(loans), getComparator(order, orderBy))
                        .slice(0, rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <TableRow
                              hover
                              onClick={event => handleClick(event, row)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                  name="checkbox"
                                />
                              </TableCell>
                              <TableCell align="left">{formatLoanStatus(row.status)}</TableCell>
                              <TableCell component="th" id={labelId} scope="row" padding="none">
                                <Tooltip title={getDateHour(row.startedAt)}>
                                  <div>{formatDate(row.startedAt)}</div>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">
                                <Link to={`/dashboard/users/${row.borrower.id}`}>
                                  {`${row.borrower.firstname} ${row.borrower.lastname}`}
                                </Link>
                              </TableCell>
                              <TableCell align="left">{row.id}</TableCell>
                              <TableCell align="left">{Math.abs(row.amount).toString() || '0'}</TableCell>
                              <TableCell align="left">{row.feesAmount?.toString() || '0'}</TableCell>
                              <TableCell align="left">{row.aprc?.toString() || '0'}</TableCell>
                              <TableCell align="left">{formatLoanType(row.product)}</TableCell>
                              <TableCell align="left">
                                {formatCreditRejectedReason(row.rejectedReason)}
                              </TableCell>
                              <TableCell align="left">{row.bankAccount.bankName}</TableCell>
                              <TableCell align="left">{row.bankAccount.iban}</TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            );
          }}
        </Query>
      </Paper>
      <FormControlLabel
        name="switch"
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Affichage recentré"
      />
      <RelatedPaymentsContainer intl={intl} creditId={selected} borrowerId={selectedBorrowerId} />
    </div>
  );
};

export default injectIntl(LoansContainer);
