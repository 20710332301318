import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import notchedOutline from '@material-ui/core/OutlinedInput';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  Switch,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  ListItemText,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import FilterListIcon from '@material-ui/icons/FilterList';

import { FormattedMessage, injectIntl } from 'react-intl';
import RelatedPaymentsContainer from '../../Loans/components/RelatedPaymentsContainer';
import { formatLoans } from '../../Loans/formatLoans';
import { useQuery } from '@apollo/client';
import GET_ALL_CREDITS_BY_BORROWER_ID from './GetAllCreditsByBorrowerId.gql';
import { formatDate, getDateHour } from '../../utils';
import { formatCreditRejectedReason } from '../../Loans/components/LoansContainer';

const DEFAULT_ROWS_PER_PAGE = 5;

const loanState = [
  { label: 'en cours', value: 'ONGOING' },
  { label: 'en erreur', value: 'FAILED' },
  { label: 'rejeté', value: 'REJECTED' },
  { label: 'repayé', value: 'DONE' },
];

const formatLoanStatus = status => {
  const state = loanState.find(s => s.value === status);
  return state ? state.label : '';
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'state', numeric: false, disablePadding: false, label: 'Statut' },
  {
    id: 'startedAt',
    numeric: false,
    disablePadding: false,
    label: 'Commencé le',
  },
  { id: 'loanId', numeric: false, disablePadding: false, label: 'ID du prêt' },
  {
    id: 'transactionAmount',
    numeric: false,
    disablePadding: false,
    label: 'Montant (hors frais)',
  },
  { id: 'feeAmount', numeric: false, disablePadding: false, label: 'Montant des frais' },
  { id: 'taeg', numeric: false, disablePadding: false, label: 'TAEG' },
  { id: 'loanType', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'rejectedReason', numeric: false, disablePadding: false, label: 'Raison du Rejet' },
  { id: 'bankName', numeric: false, disablePadding: false, label: 'Banque' },
  {
    id: 'bankAccountName',
    numeric: false,
    disablePadding: false,
    label: 'Compte Bancaire',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    marginRight: 20,
    width: '30%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, filter, setFilter } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <Tooltip title="Filtre">
        <IconButton aria-label="Filtre" onClick={() => setFilter(!filter)}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          <FormattedMessage id="components.utils.loans" />
        </Typography>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cssLabel: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  filterInput: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '1em',
    minWidth: '100px',
  },
}));

const RelatedLoansContainer = ({ intl, borrowerId }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [filter, setFilter] = React.useState(false);
  const [loans, setLoans] = React.useState([]);
  const [status, setStatus] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    setSelected(prevSelected => (prevSelected === id ? null : id));
  };

  const isSelected = id => selected === id;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  useQuery(GET_ALL_CREDITS_BY_BORROWER_ID, {
    variables: {
      borrowerId,
      status: {
        included: status ? status : [],
        excluded: [],
      },
      pagination: {
        page: null,
        pageLimit: null,
      },
    },
    onCompleted(response) {
      setLoans(response.getAllCreditsByBorrowerId.credits);
    },
    fetchPolicy: 'cache-and-network',
  });

  const formatLoanType = type => {
    if (type === 'DJANGO_LATER_CLASSICAL') return 'Transaction';
    if (type === 'DJANGO_LATER_FREE_AMOUNT') return 'Montant libre';
    return '';
  };

  const arrangeStateFilter = arr => {
    setSelected(null);
    const emptyStringIndex = arr.indexOf('');
    if (emptyStringIndex > -1) {
      arr.splice(emptyStringIndex, 1);
      arr.unshift('');
    }
    return arr;
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, loans.length - page * rowsPerPage);
  if (loans)
    return (
      <>
        <div className={classes.root}>
          <Paper className={classes.paper}>
            <>
              {filter && (
                <Grid container flexDirection="row" justify="flex-start">
                  <FormControl variant="outlined" className={classes.filterInput}>
                    <InputLabel
                      classes={{
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      }}
                      id="select-state-label"
                    >
                      <FormattedMessage id="components.utils.statut" />
                    </InputLabel>
                    <Select
                      multiple
                      name="state"
                      labelId="select-state-label"
                      value={status}
                      onChange={e => {
                        setStatus(arrangeStateFilter(e.target.value));
                      }}
                      input={<OutlinedInput id="select-multiple-chip" label="Selectionnez un statut" />}
                      renderValue={selected => (
                        <Box className={classes.chips}>
                          {selected.map(value => {
                            const selectedOption = loanState.find(option => option.value === value);
                            return (
                              <Chip
                                key={value}
                                label={selectedOption ? selectedOption.label : value}
                                className={classes.chip}
                              />
                            );
                          })}
                        </Box>
                      )}
                    >
                      {loanState.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          <Checkbox checked={status.indexOf(option.value) > -1} />
                          <ListItemText primary={option.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <EnhancedTableToolbar
                filter={filter}
                setFilter={setFilter}
                selected={selected}
                setSelected={setSelected}
              />
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={loans.length}
                  />
                  <TableBody>
                    {stableSort(formatLoans(loans), getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={event => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                                name="checkbox"
                              />
                            </TableCell>
                            <TableCell align="left">{formatLoanStatus(row.status)}</TableCell>
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              <Tooltip title={getDateHour(row.startedAt)}>
                                <div>{formatDate(row.startedAt)}</div>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="left">{row.id}</TableCell>
                            <TableCell align="left">{Math.abs(row.amount).toString() || '0'}</TableCell>
                            <TableCell align="left">{row.feesAmount?.toString() || '0'}</TableCell>
                            <TableCell align="left">{row.aprc?.toString() || '0'}</TableCell>
                            <TableCell align="left">{formatLoanType(row.product)}</TableCell>
                            <TableCell align="left">
                              {formatCreditRejectedReason(row.rejectedReason)}
                            </TableCell>
                            <TableCell align="left">{row.bankAccount.bankName}</TableCell>
                            <TableCell align="left">{row.bankAccount.iban}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50]}
                component="div"
                count={loans.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          </Paper>
          <FormControlLabel
            name="switch"
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Affichage recentré"
          />
        </div>
        {selected && (
          <RelatedPaymentsContainer creditId={selected} borrowerId={borrowerId}></RelatedPaymentsContainer>
        )}
      </>
    );
  return null;
};

export default injectIntl(RelatedLoansContainer);
