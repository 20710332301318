import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import notchedOutline from '@material-ui/core/OutlinedInput';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
  FormControlLabel,
  Switch,
  Grid,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Box,
  Chip,
  ListItemText,
  MenuItem,
} from '@material-ui/core';

import { FormattedMessage, injectIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import GET_USER_FICP_HISTORIES from '../getFicpDatas.gql';
import { EnhancedTableHead } from './EnhancedTableHead';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { DescriptionCell } from './DescriptionCell';
import { format } from 'date-fns';

const DEFAULT_ROWS_PER_PAGE = 10;

const ficpStatus = [
  { label: 'FICHE', value: 'registered' },
  { label: 'NON_FICHE', value: 'not_registered' },
  { label: 'AUTRE_PERSONNE_FICHE', value: 'homonymy' },
  { label: 'ECHEC_INTERROGATION', value: 'call_failed' },
  { label: 'EN_ATTENTE_REPONSE', value: 'on_hold' },
  { label: 'other response', value: 'other' },
  { label: 'no call or call with error', value: 'not_applicable' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  cssLabel: {
    opacity: 0.6,
    '&$cssFocused': {
      color: 'black',
    },
  },
  cssFocused: {},
  cssOutlinedInput: {
    [`& .${notchedOutline}, &$cssFocused`]: {
      borderColor: theme.palette.textFieldsOutline,
    },
  },
  filterInput: {
    marginTop: '1em',
    marginRight: '0.5em',
    marginLeft: '1em',
    minWidth: '100px',
  },
}));

const RelatedFICPHistoriesContainer = ({ intl, userId }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [selected, setSelected] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [filter, setFilter] = React.useState(false);
  const [status, setStatus] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    setSelected(prevSelected => (prevSelected === id ? null : id));
  };

  const isSelected = id => selected === id;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };

  const { data } = useQuery(GET_USER_FICP_HISTORIES, {
    variables: {
      userId,
    },
    skip: !userId,
  });
  let FICPHistories = data?.getFicpDatas ?? [];
  if (status?.length) FICPHistories = FICPHistories.filter(ficp => status.includes(ficp.status));

  const arrangeStateFilter = arr => {
    setSelected(null);
    const emptyStringIndex = arr.indexOf('');
    if (emptyStringIndex > -1) {
      arr.splice(emptyStringIndex, 1);
      arr.unshift('');
    }
    return arr;
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, FICPHistories.length - page * rowsPerPage);
  if (FICPHistories)
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <>
            {filter && (
              <Grid container flexDirection="row" justify="flex-start">
                <FormControl variant="outlined" className={classes.filterInput}>
                  <InputLabel
                    classes={{
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    }}
                    id="select-state-label"
                  >
                    <FormattedMessage id="components.utils.statut" />
                  </InputLabel>
                  <Select
                    multiple
                    name="state"
                    labelId="select-state-label"
                    value={status}
                    onChange={e => {
                      setStatus(arrangeStateFilter(e.target.value));
                    }}
                    input={<OutlinedInput id="select-multiple-chip" label="Selectionnez un statut" />}
                    renderValue={selected => (
                      <Box className={classes.chips}>
                        {selected.map(value => {
                          const selectedOption = ficpStatus.find(option => option.value === value);
                          return (
                            <Chip
                              key={value}
                              label={selectedOption ? selectedOption.label : value}
                              className={classes.chip}
                            />
                          );
                        })}
                      </Box>
                    )}
                  >
                    {ficpStatus.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox checked={status.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <EnhancedTableToolbar
              filter={filter}
              setFilter={setFilter}
              selected={selected}
              setSelected={setSelected}
              userId={userId}
            />
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={FICPHistories.length}
                />
                <TableBody>
                  {stableSort(FICPHistories, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      return (
                        <TableRow
                          hover
                          onClick={event => handleClick(event, row.id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell align="left">statusFICP</TableCell>
                          <DescriptionCell status={row.status} />
                          <TableCell align="left">
                            {format(new Date(row.createdAt), 'MMM dd, yyyy hh:mm')}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 50]}
              component="div"
              count={FICPHistories.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        </Paper>
        <FormControlLabel
          name="switch"
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Affichage recentré"
        />
      </div>
    );
  return null;
};

export default injectIntl(RelatedFICPHistoriesContainer);
