import { CheckCircle } from '@material-ui/icons';
import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { today, getLocalTimeZone } from '@internationalized/date';
import ME from '../../../../../components/graphQL/queries/Me.gql';
import { format } from 'date-fns';

const {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  Grid,
  Paper,
  Box,
  TextareaAutosize,
} = require('@material-ui/core');
const { Calendar } = require('./Calendar');

/**
 * @typedef OnSubmitData
 * @property {Date} date
 * @property {string} description
 *
 * @typedef CalendarModalProps
 * @property {(props: OnSubmitData)=>Promise<void>} onSubmit
 * @property {boolean}  open
 * @property {()=>void}  setOpen
 * @param {CalendarModalProps} param0
 * @returns
 */
const PromesseModal = ({ open, setOpen, onSubmit: _onSubmit }) => {
  const [step, setStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState();
  const [description, setDescription] = useState('');
  const [operatorName, setOperatorName] = useState('');
  useQuery(ME, {
    onCompleted(response) {
      setOperatorName(`${response.Me.firstname} ${response.Me.lastname}`);
    },
  });

  let date = useMemo(() => {
    if (!selectedDate) return undefined;
    return selectedDate.toDate();
  }, [selectedDate]);
  const onSubmit = async () => {
    _onSubmit?.({ date: date.toDate(), description })?.then(() => {
      setStep(prev => prev + 1);
    });
  };
  return (
    <Dialog
      PaperProps={{
        style: {
          backgroundColor: '#F8F9FB',
          padding: 32,
          borderRadius: '16px',
        },
      }}
      open={open}
      onClose={() => setOpen(false)}
      maxWidth={step === 2 ? 'xs' : 'md'}
      fullWidth={step !== 0}
    >
      {step === 0 ? (
        <Step1
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setStep={setStep}
          onClose={() => setOpen(false)}
        ></Step1>
      ) : null}
      {step === 1 ? (
        <Step2
          promesseDate={date?.format('YYYY-MM-DD')}
          description={description}
          operatorName={operatorName}
          setDescription={setDescription}
          onSubmit={onSubmit}
        ></Step2>
      ) : null}
      {step === 2 ? <Step3 onClose={() => setOpen(false)}></Step3> : null}
    </Dialog>
  );
};

/**
 * @typedef Step1Props
 * @property {import("react-aria").DateValue} selectedDate
 * @property {React.Dispatch<React.SetStateAction<import("react-aria").DateValue>>} setSelectedDate
 * @param {Step1Props} param0
 * @returns
 */
const Step1 = ({ setStep, onClose, selectedDate, setSelectedDate }) => (
  <>
    <DialogTitle>Date de la promesse de paiement</DialogTitle>
    <DialogContent>
      <Calendar
        minValue={today(getLocalTimeZone())}
        focusedValue={selectedDate}
        onFocusChange={setSelectedDate}
        weekdayStyle="long"
      ></Calendar>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => setStep(prev => prev + 1)}
        style={{
          display: 'flex',
          padding: '9px 45.5px 9px 44.5px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor: '#081433',
        }}
      >
        <Typography
          style={{
            color: '#FFFFFF',
            textAlign: 'center',
            fonFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '22px',
          }}
        >
          Valider
        </Typography>
      </Button>
      <Button
        onClick={onClose}
        style={{
          display: 'flex',
          padding: '9px 45.5px 9px 44.5px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '16px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Typography
          style={{
            color: '#081433',
            textAlign: 'center',
            fonFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '22px',
          }}
        >
          Annuler
        </Typography>
      </Button>
    </DialogActions>
  </>
);

const Step2 = ({
  date = format(new Date(), 'dddd MM/dd/yyyy - hh:mm:ss'),
  promesseDate = format(new Date(), 'yyyy-MM-dd'),
  operatorName = '',
  description,
  setDescription,
  onSubmit,
}) => {
  return (
    <>
      <DialogTitle>Ajouter un commentaire</DialogTitle>
      <DialogContent>
        <Grid container style={{ gap: 12 }}>
          <Grid item xs>
            <Paper style={{ padding: 16, marginBottom: 16 }}>
              <Grid container justifyContent="space-between">
                <Grid item xs>
                  <Grid container justifyContent="space-between" alignItems="center" xs>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 16,
                      }}
                    >
                      <Typography
                        align="left"
                        style={{
                          fontSize: 15,
                          fontStyle: 'normal',
                          fontWeight: '400',
                          lineHeight: '22px',
                          fontFamily: 'Poppins',
                          color: '#6C676E',
                        }}
                      >
                        {date}
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        fontSize: '15px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '22px',
                        fontFamily: 'Poppins',
                        color: '#000000',
                        backgroundColor: '#F8F9FB',
                        padding: '4px',
                        borderRadius: '4px',
                      }}
                    >
                      {operatorName}
                    </Typography>
                  </Grid>
                  <Typography
                    style={{
                      fontWeight: '600',
                      fontStyle: 'normal',
                      lineHeight: '22px',
                      fontSize: 17,
                      marginTop: 4,
                      marginBottom: 8,
                    }}
                  >
                    Promesse de paiement le {promesseDate}
                  </Typography>
                  <TextareaAutosize
                    placeholder="Enter description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    minRows={3}
                    style={{
                      caretColor: '#F76C54',
                      color: '#000000',
                      textAlign: 'justify',
                      fontFamily: 'Poppins',
                      fontSize: '17px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '22px',
                      width: '100%',
                      backgroundColor: '#F8F9FB',
                      borderWidth: '0px',
                      padding: '8px',
                      resize: 'none',
                      marginBottom: '16px',
                      border: 'none',
                    }}
                  />
                  <Button
                    style={{
                      textAlign: 'center',
                      fontFamily: 'Poppins',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '22px',
                      backgroundColor: '#FF6B4F',
                      borderRadius: '8px',
                      padding: '4px 8px',
                      color: '#FFFFFF',
                    }}
                    onClick={onSubmit}
                  >
                    ENREGISTRER
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};
const Step3 = ({ onClose }) => (
  <Grid container justifyContent="center" direction="column" alignItems="center" style={{ gap: 32 }}>
    <Grid item>
      <Grid container style={{ gap: 16 }} alignItems="center" direction="column">
        <CheckCircle style={{ width: '50px', height: '50px', color: '#47D065' }} />
        <Typography
          style={{
            textAlign: 'center',
            fontFamily: 'Poppins',
            fontSize: '23px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '22px',
          }}
        >
          Promesse ajoutée Sous-titre
        </Typography>
      </Grid>
    </Grid>
    <Button
      onClick={onClose}
      style={{
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '22px',
        borderRadius: '4px',
        padding: '9px 36px',
        backgroundColor: '#000000',
        color: '#FFFFFF',
      }}
    >
      TERMINER
    </Button>
  </Grid>
);

export default PromesseModal;
