import { Grid, Paper, Typography } from '@material-ui/core';
import { format } from 'date-fns';

/**
 * @typedef PromisePayments
 * @property {string} userId
 * @property {string} operatorId
 * @property {string} operatorName
 * @property {string} message
 * @property {string} promisedAt
 * @property {string} createdAt
 * @typedef RelatedPromesseContainer
 * @property {PromisePayments[]} promesses
 *
 * @param {RelatedPromesseContainer} param0
 */
const RelatedPromesseContainer = ({ promesses }) => {
  return (
    <Paper style={{ padding: 16 }}>
      {promesses.map(promesse => (
        <PromesseItem
          key={promesse.createdAt}
          date={format(promesse.promisedAt, 'yyyy-MM-dd')}
          message={promesse.message}
          operatorName={promesse.operatorName}
        ></PromesseItem>
      ))}
    </Paper>
  );
};

const PromesseItem = ({
  date = format(new Date(), 'yyyy-MM-dd'),
  message = '',
  operatorName = 'Lorem Ipsum',
}) => {
  return (
    <Grid container direction="column">
      <Grid xs>
        <Grid container>
          <Grid xs>
            <Typography
              align="left"
              style={{
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '22px',
                fontFamily: 'Poppins',
                color: '#000000',
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              Promesse de paiement le {date}
            </Typography>
          </Grid>
          <Grid xs>
            <Typography
              align="right"
              style={{
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '22px',
                fontFamily: 'Poppins',
                color: '#000000',
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              {operatorName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs>
        <Grid container>
          <Grid xs>
            <Typography
              align="left"
              style={{
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '22px',
                fontFamily: 'Poppins',
                color: '#000000',
                padding: '4px',
                borderRadius: '4px',
              }}
            >
              {message}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RelatedPromesseContainer;
