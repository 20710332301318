// For development build

export const environment = {
  NODE_ENV: 'development',
  INTERCOM_APP_ID: '',
  PUBLIC_URL: '',
  DASHBOARD_HOST: 'https://develop.django-app.com',
  BUDGET_DASHBOARD_REDIRECT_URI: '/dashboard/account',
  BUDGET_CLIENTID: '94298878',
  BUDGET_API_HOST: 'develop-sandbox',
  BUDGET_RECONNEXION_REDIRECT_URI: 'https://develop.django-app.com/bankaccount/reconnexion',
  BUDGET_REDIRECT_URI: 'https://develop.django-app.com/get-app',
  BACKEND_URL: 'https://develop.django-app.com/graphql',
  RETRY_PAYMENT_FEATURE_FLAG: 'ON',
};
