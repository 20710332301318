import {
  inviteYourTeam,
  schemaRemoveAmlCftAlert,
  schemaUpdateUserInfo,
} from '../../../../components/utils/joiValidationSchemas';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Cancel, CheckCircle, Email as EmailIcon, Phone as PhoneIcon } from '@material-ui/icons';
import { ProgressBar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import { Link } from 'react-router-dom';
import DefaultProfilePicture from '../../../../images/profilePicture.png';
import { ReactComponent as VerifiedBoxVerifiedIcon } from '../../../../images/verifiedBoxVerifiedIcon.svg';
import { ReactComponent as VerifiedBoxRejectedIcon } from '../../../../images/verifiedBoxRejectedIcon.svg';
import { ReactComponent as VerifiedBoxFraudIcon } from '../../../../images/verifiedBoxFraudIcon.svg';
import { ReactComponent as VerifiedBoxProcessingIcon } from '../../../../images/verifiedBoxProcessingIcon.svg';
import DjangoLogo from '../../../../public/DJANGO.png';
import JoeLogo from '../../../../public/JOE.png';
import LbpLogo from '../../../../public/LBP.png';
import UPDATE_USER_INFO from './updateUserInfo.gql';
import REMOVE_USER_AML_CFT_ALERT from './removeAmlCftAlert.gql';
import RENEW_EMAIL from './renewEmail.gql';
import SAVE_ADDRESS from './saveAddress.gql';
import GET_USER_BY_ID from './getUserById.gql';
import GET_LATEST_SCORE from './getLatestScore.gql';
import GET_USER_BANK_ACCOUNTS_STATUS from './getUserBankAccountsStatus.gql';
import GET_MY_CONSENTS from './getMyConsents.gql';
import axios from 'axios';
import { Pagination } from '@material-ui/lab';
import { format } from 'date-fns';

const moment = new MomentUtils();

const SIDENAV_WIDTH = 300;
const WIDTH = window.innerWidth - SIDENAV_WIDTH;

const BI_LINK_STATUS = {
  UNINITIATED: 'uninitiated',
  PROCESSING: 'processing',
  PROCESSED: 'processed',
  ABORTED: 'aborted',
};

const KYC_STATUS = {
  ONBOARDING: 'onboarding',
  ACQUIRED: 'acquired',
  DECLARED: 'declared',
  VERIFIED: 'verified',
  REJECTED: 'rejected',
};

const KYC_REJECTION_REASON = {
  1: "La vérification de l'identité a échoué",
  2: "Impossible d'utiliser le numéro de téléphone",
  3: 'Compte dupliqué',
  4: "Les informations personnelles du compte ne correspondent pas aux informations mentionnées sur la pièce d'identité",
};

export const CenterWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 675px) {
    padding-right: 0.5em;
  }
`;

export const Text = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const SubTitle = styled.div`
  font-size: 20px;
  margin-left: 20px;
  color: ${props => (props.alert ? 'red' : 'black')};
`;

export const InfoBox = styled.div`
  display: flex;
  background-color: ${props => (props.primary ? '#00E998' : 'red')};
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-left: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
`;

const VerifiedBoxDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 8px 12px 8px 12px;
  font-size: 16px;
  margin-bottom: 5px;
  width: max-content;
`;

const VerifiedBox = ({ status, rejectionReason }) => {
  let styles;
  let statusLabel;
  let Icon;
  switch (status) {
    case KYC_STATUS.VERIFIED:
      styles = {
        backgroundColor: '#BDFFBC',
        color: '#10C001',
        border: '1px solid #10C001',
      };
      statusLabel = 'Vérifié';
      Icon = VerifiedBoxVerifiedIcon;
      break;
    case KYC_STATUS.DECLARED:
      styles = {
        backgroundColor: '#FFD0BC',
        color: '#FF6B00',
        border: '1px solid #FF6B00',
      };
      statusLabel = 'Fraude vérifiée';
      Icon = VerifiedBoxFraudIcon;
      break;
    case KYC_STATUS.REJECTED:
      styles = {
        backgroundColor: '#FFBCBC',
        color: '#FF0000',
        border: '1px solid #FF0000',
      };
      statusLabel = 'Refusé';
      Icon = VerifiedBoxRejectedIcon;
      break;
    case KYC_STATUS.ONBOARDING:
    case KYC_STATUS.ACQUIRED:
      styles = {
        backgroundColor: '#BCDBFF',
        color: '#0075FF',
        border: '1px solid #0075FF',
      };
      statusLabel = 'en cours';
      Icon = VerifiedBoxProcessingIcon;
      break;
  }
  if (!statusLabel) return null;

  return (
    <Box style={{ marginLeft: 30, width: 'auto' }}>
      <VerifiedBoxDiv style={styles}>
        <Icon style={{ marginRight: 5 }} />
        {statusLabel}
      </VerifiedBoxDiv>
      {status === KYC_STATUS.REJECTED && rejectionReason && (
        <Text style={{ fontSize: 10, color: '#6D6D6D', textAlign: 'left' }}>
          {KYC_REJECTION_REASON[rejectionReason]}
        </Text>
      )}
    </Box>
  );
};

export const ContactTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
`;

export const ContactSubTitle = styled.div`
  font-size: 16px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  padding-top: 32px;
  padding-bottom: 16px;
  padding-left: 10px;
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: ${({ loading }) => (loading ? 'center' : 'space-between')};
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 50px;
`;

export const ProfileInformations = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NameContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const Picture = styled.img`
  width: ${WIDTH > 1050 ? 250 : 150}px;
`;

export const LoanCapacityContainer = styled.div`
  display: flex;
  width: 300px;
  height: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 20px;
`;

const UpdateUserInfo = ({ user }) => {
  const [opened, setOpened] = useState(false);
  const handleClose = () => setOpened(false);
  const handleOpen = () => setOpened(true);
  const [firstName, setFirstName] = useState(user.firstname);
  const [lastName, setLastName] = useState(user.lastname);
  const [birthDate, setBirthDate] = useState(format(new Date(user.birthdate), 'yyyy-MM-dd'));
  const [update] = useMutation(UPDATE_USER_INFO, {
    variables: {
      userId: user.id,
      firstName,
      lastName,
      birthDate,
    },
    refetchQueries: ['getUser'],
  });
  const [errors, setErrors] = useState([]);
  const handleSubmit = async event => {
    event.preventDefault();
    const errors = schemaUpdateUserInfo.validate({
      firstName,
      lastName,
      birthDate,
    });
    if (errors?.error?.details) setErrors(errors.error.details || []);
    else {
      await update();
      handleClose();
    }
  };
  useEffect(() => {
    setFirstName(user.firstname);
    setLastName(user.lastname);
    setBirthDate(format(new Date(user.birthdate), 'yyyy-MM-dd'));
  }, [user, opened]);
  return (
    <>
      <Row
        style={{
          float: 'right',
          width: '100%',
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <Button variant="contained" color="primary" onClick={handleOpen}>
          Update User Info
        </Button>
      </Row>
      <Dialog onClose={handleClose} open={opened}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Update User Info</DialogTitle>
          <DialogContent>
            <Column>
              <TextField
                error={errors.filter(e => e.path.includes('firstName')).length > 0}
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                label="First Name"
                name="firstName"
                placeholder="Enter First name"
              />
              <TextField
                error={errors.filter(e => e.path.includes('lastName')).length > 0}
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                label="Last Name"
                name="lastName"
                placeholder="Enter Last name"
              />
              <TextField
                error={errors.filter(e => e.path.includes('birthDate')).length > 0}
                format="DD/MM/YYYY"
                value={birthDate}
                onChange={e => setBirthDate(e.target.value)}
                type="date"
                label="Date of Birth"
                name="birthDate"
                placeholder="Date of birth"
              />
            </Column>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export const ConfirmRemoveAmlCftAlert = ({ user, openConfirmAmlCftAlert, setOpenConfirmAmlCftAlert }) => {
  const [alertRemovalReason, setAlertRemovalReason] = useState('');
  const [errors, setErrors] = useState([]);
  const closeDialog = () => setOpenConfirmAmlCftAlert(false);
  const [removeAmlCftAlert] = useMutation(REMOVE_USER_AML_CFT_ALERT, {
    refetchQueries: ['getUser'],
  });
  const handleSubmit = async event => {
    event.preventDefault();
    const errors = schemaRemoveAmlCftAlert.validate({ alertRemovalReason });
    if (errors?.error?.details) setErrors(errors.error.details || []);
    else {
      await removeAmlCftAlert({
        variables: {
          id: user.id,
          reason: alertRemovalReason,
        },
      });
      setAlertRemovalReason('');
      closeDialog();
    }
  };
  return (
    <Dialog onClose={closeDialog} open={openConfirmAmlCftAlert}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Retirer alerte AML & CFT</DialogTitle>
        <DialogContent>
          <Column>
            <TextField
              error={errors.filter(e => e.path.includes('firstName')).length > 0}
              value={alertRemovalReason}
              onChange={e => setAlertRemovalReason(e.target.value)}
              label="Reason"
              name="Reason"
              placeholder="Enter Reason"
              multiline
              rows={4}
            />
          </Column>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={closeDialog}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const BankAccountStatus = ({ bankAccountStatus }) => {
  const { linkedBankAccount, state, active, userNameAlert } = bankAccountStatus;

  if (linkedBankAccount && state === 'processed' && active)
    return (
      <Chip
        style={{ backgroundColor: '#BDFFBC', color: '#10C001' }}
        icon={<CheckCircle style={{ color: '#10C001' }} />}
        label="Connecté"
      />
    );
  if (!active && userNameAlert)
    return (
      <Chip
        style={{ backgroundColor: '#FFBCBC', color: '#FF0000' }}
        icon={<Cancel style={{ color: '#FF0000' }} />}
        label="Non correspondant"
      />
    );
  if (!active)
    return (
      <Chip
        style={{ backgroundColor: '#FFBCBC', color: '#FF0000' }}
        icon={<Cancel style={{ color: '#FF0000' }} />}
        label="Non connecté"
      />
    );
  return null;
};

export const ProfileCard = ({
  user,
  newKyc,
  scaVerifier,
  notificationDevice,
  hasEligibleTransactions,
  profilePicture,
  loading,
  hasEligibleTransactionsLoading,
  riskScore,
  fraudScoring,
  device,
}) => {
  const [opened, setOpened] = useState(false);
  const [addressOpened, setAddressOpened] = useState(false);
  const [errors, setErrors] = useState([]);
  const handleOpen = () => setOpened(true);
  const handleOpenAddress = () => setAddressOpened(true);

  ////////////////////////// CONFIRM EMAIL//////////////////////////////////
  const [comfirmEmail, setComfirmEmail] = useState(false);
  const handleClose = () => {
    setComfirmEmail(false);
    setOpened(false);
  };
  const [email, setEmail] = useState(user?.email);
  const [renewEmail] = useMutation(RENEW_EMAIL, {
    variables: {
      userId: user?.id,
      email,
    },
    refetchQueries: ['getUser'],
  });
  const { data } = useQuery(GET_USER_BANK_ACCOUNTS_STATUS, {
    variables: { userId: user?.id },
    skip: !user?.id,
  });
  const bankAccountsStatuses = data?.getUserBankAccountsStatus ?? [];

  const handleSubmit = async event => {
    event.preventDefault();
    const validationErrors = inviteYourTeam.email.validate(email);
    if (validationErrors?.error?.details) {
      setErrors(validationErrors.error.details || []);
      return;
    }
    try {
      await renewEmail();
      setComfirmEmail(true);
    } catch (error) {
      if (error.message.includes('EMAIL_ALREADY_USED')) {
        setErrors(['Cette adresse e-mail est déjà utilisée par un autre compte']);
      } else {
        setErrors(['Une erreur est survenue']);
      }
    }
  };
  useEffect(() => {
    setEmail(user?.email);
  }, [user, opened]);
  ////////////////////////////////CONFIRM EMAIL END////////////////////////////////

  //////////////////////////////////UPDATE ADDRESS////////////////////////////////
  const [confirmAddress, setConfirmAddress] = useState(false);
  const handleCloseUpdateAddress = () => {
    setConfirmAddress(false);
    setAddressOpened(false);
    setErrors([]);
  };

  const rawAddress = `${newKyc?.customer?.address?.streetNumber || ''} ${
    newKyc?.customer?.address?.street || ''
  } ${newKyc?.customer?.address?.zipCode || ''} ${newKyc?.customer?.address?.city || ''}`;
  const [originalRawAddress, setOriginalRawAddress] = useState('');

  const additionalAddress = newKyc?.customer?.address?.additionalAddress || '';
  const [originalAdditionalAddress, setOriginalAdditionalAddress] = useState(additionalAddress);

  const [updateAddress] = useMutation(SAVE_ADDRESS, {
    variables: {
      userId: user?.id,
      rawAddress: originalRawAddress,
      additionalAddress: originalAdditionalAddress,
    },
    refetchQueries: ['getKycById'],
  });

  const handleSubmitAddress = async event => {
    event.preventDefault();
    if (originalRawAddress === rawAddress && originalAdditionalAddress === additionalAddress) {
      setErrors(['Veuillez sélectionner votre nouvelle adresse parmi les suggestions']);
      return;
    }
    try {
      await updateAddress();
      setConfirmAddress(true);
    } catch (error) {
      setErrors(['Une erreur est survenue lors de la mise à jour de l’adresse']);
    }
  };

  useEffect(() => {
    setOriginalRawAddress(rawAddress);
    setOriginalAdditionalAddress(additionalAddress);
  }, [newKyc, addressOpened, rawAddress, additionalAddress]);

  const AddressAutocomplete = ({ value, onChange }) => {
    const [rawAddress, setRawAddress] = useState(value);
    const [suggestions, setSuggestions] = useState([]);
    const handleAddressChange = async e => {
      const address = e.target.value;

      setRawAddress(address);

      if (address.length < 5) {
        setSuggestions([]);
        return;
      }
      try {
        const result = await axios.get(`https://api-adresse.data.gouv.fr/search/?q=${address}`);
        if (result.data && result.data.features) {
          setSuggestions(result.data.features.map(feature => feature.properties.label));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des suggestions d'adresse:", error);
        setSuggestions([]);
      }
    };
    const handleSelectSuggestion = suggestion => {
      setRawAddress(suggestion);
      onChange(suggestion);
      setErrors([]);
    };
    return (
      <div>
        <input
          style={{ width: '100%', minHeight: '40px', padding: '5px 10px' }}
          type="text"
          value={rawAddress}
          onChange={handleAddressChange}
          placeholder="Entrer l'adresse"
        />
        {suggestions.length > 0 && (
          <ul style={{ padding: 0, margin: 0 }}>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                style={{
                  padding: '10px 15px',
                  cursor: 'pointer',
                  listStyle: 'none',
                }}
                onClick={() => handleSelectSuggestion(suggestion)}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };
  //////////////////////////////////UPDATE ADDRESS END/////////////////////////////////

  const rate =
    user?.leftLoanCapacity &&
    user?.totalLoanCapacity &&
    (user.leftLoanCapacity / user.totalLoanCapacity) * 100;
  let fraudSuspicionText;
  if (!user?.adminAuthorization) {
    if (user?.fraudSuspicion) {
      fraudSuspicionText = 'Fraude';
    }
    if (fraudScoring?.fraudSuspicion) {
      fraudSuspicionText = 'Fraude Identité';
    }
    if (device?.fraudSuspicion) {
      fraudSuspicionText = 'Fraude Appareil';
    }
  }

  const { loading: originLoading, data: getUserByIdData } = useQuery(GET_USER_BY_ID, {
    variables: { id: user?.id },
    skip: !user?.id,
  });

  const { loading: lbpRiskScoreLoading, data: getLatestLbpRiskScoreData } = useQuery(GET_LATEST_SCORE, {
    variables: { userId: user?.id, userScoringType: 'lbp_risk' },
    skip: !user?.id,
  });
  const { loading: algoanScoreLoading, data: getLatestAlgoanScoreData } = useQuery(GET_LATEST_SCORE, {
    variables: { userId: user?.id, userScoringType: 'algoan_credit_score' },
    skip: !user?.id,
  });
  const { data: getMyConsentsData } = useQuery(GET_MY_CONSENTS, {
    variables: { userId: user?.id },
    skip: !user?.id,
    onCompleted: console.log,
    onError: console.error,
  });
  const appUsageCGU = getMyConsentsData?.getMyConsent?.terms?.appUsage?.toSorted((b, a) => {
    return moment.date(a.date).unix() - moment.date(b.date).unix();
  })?.[0];
  const djangoLaterCGU = getMyConsentsData?.getMyConsent?.terms?.djangoLater?.toSorted((b, a) => {
    return moment.date(a.date).unix() - moment.date(b.date).unix();
  })?.[0];

  const appUsageDate = appUsageCGU?.date ? moment.date(appUsageCGU.date).format('MMM DD, YYYY') : 'N/A';
  const appUsageVersion = appUsageCGU?.version ?? 'N/A';
  const djangoLaterDate = djangoLaterCGU?.date
    ? moment.date(djangoLaterCGU.date).format('MMM DD, YYYY')
    : 'N/A';
  const djangoLaterVersion = djangoLaterCGU?.version ?? 'N/A';

  return (
    <>
      <Paper style={{ witdh: WIDTH }}>
        <ProfileContainer>
          {loading ? (
            <CardContainer>
              <CircularProgress />
            </CardContainer>
          ) : (
            <>
              <NameContainer style={{ marginLeft: 10 }}>
                <Picture src={profilePicture || DefaultProfilePicture} />
              </NameContainer>
              <ProfileInformations>
                <NameContainer style={{ marginLeft: -250 }}>
                  <Row>
                    <Column>
                      <Text>
                        {user?.firstname} {user?.lastname?.toUpperCase()}
                      </Text>
                      {(!user?.fraudSuspicion || user?.adminAuthorization) && riskScore?.riskBalance > 0 && (
                        <InfoBox primary style={{ width: 200 }}>
                          Eligible pour Carte Joe
                        </InfoBox>
                      )}
                      {fraudSuspicionText && (
                        <InfoBox
                          style={{
                            width: 'auto',
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          {fraudSuspicionText}
                        </InfoBox>
                      )}
                      {user?.amlCftAlert && !user?.adminAuthorization && (
                        <InfoBox verified={false} style={{ width: 150 }}>
                          AML & CFT alerte
                        </InfoBox>
                      )}
                    </Column>
                    <Column style={{ maxWidth: 300 }}>
                      <VerifiedBox status={newKyc?.kycStatus} rejectionReason={newKyc?.kycRejectionReason} />
                    </Column>
                  </Row>
                  {user?.commonName && (
                    <Row>
                      <Text>Nom marital : {user?.commonName}</Text>
                    </Row>
                  )}
                  <Row style={{ marginTop: 5 }}>
                    <ContactTitle>ID:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10 }}>{user?.id}</ContactSubTitle>
                  </Row>
                  <Row style={{ marginTop: 10, width: '100%' }}>
                    <ContactSubTitle
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      <div>
                        <EmailIcon /> {user?.email}
                      </div>
                      <Button variant="contained" color="primary" onClick={handleOpen}>
                        METTRE À JOUR
                      </Button>
                    </ContactSubTitle>
                  </Row>
                  <Row style={{ marginTop: 10 }}>
                    <PhoneIcon /> {user?.phone}
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <ContactTitle>Né le:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'italic' }}>
                      {user?.birthdate && moment.format(moment.date(user?.birthdate), 'fullDate')}
                    </ContactSubTitle>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <ContactSubTitle>Origine: </ContactSubTitle>
                    <ContactTitle style={{ marginLeft: 10, marginRight: 10 }}>
                      {getUserByIdData?.getUserById?.origin}
                    </ContactTitle>
                    {originLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        {getUserByIdData?.getUserById?.origin === 'DJANGO' && (
                          <img
                            src={DjangoLogo}
                            alt="Django Logo"
                            style={{
                              maxWidth: 25,
                              borderRadius: 5,
                              maxHeight: 25,
                              marginRight: 10,
                            }}
                          />
                        )}
                        {getUserByIdData?.getUserById?.origin === 'JOE' && (
                          <img
                            src={JoeLogo}
                            alt="Joe Logo"
                            style={{
                              maxWidth: 25,
                              borderRadius: 5,
                              maxHeight: 25,
                              marginRight: 10,
                            }}
                          />
                        )}
                        {getUserByIdData?.getUserById?.origin === 'LBP' && (
                          <img
                            src={LbpLogo}
                            alt="Lbp Logo"
                            style={{
                              maxWidth: 25,
                              borderRadius: 5,
                              maxHeight: 25,
                              marginRight: 10,
                            }}
                          />
                        )}
                      </>
                    )}
                    <ContactSubTitle style={{ marginLeft: 10 }}>| Canal d'origine:</ContactSubTitle>
                    <ContactTitle style={{ marginLeft: 10 }}>{scaVerifier?.origin}</ContactTitle>
                  </Row>

                  <Row
                    style={{
                      marginTop: 5,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <ContactTitle>Adresse:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'italic' }}>
                      {newKyc?.customer.address.streetNumber} {newKyc?.customer.address.street}
                      {','} {newKyc?.customer.address.zipCode} {newKyc?.customer.address.city}
                    </ContactSubTitle>
                    <Button variant="contained" color="primary" onClick={handleOpenAddress}>
                      METTRE À JOUR
                    </Button>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <ContactTitle>Complément d'adresse:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'italic' }}>
                      {newKyc?.customer.address.additionalAddress}
                    </ContactSubTitle>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <ContactTitle>Inscrit le:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'italic' }}>
                      {user?.createdAt && moment.format(moment.date(user?.createdAt), 'fullDateTime24h')}
                    </ContactSubTitle>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <ContactTitle>CGU DL:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'italic' }}>
                      {`${djangoLaterDate}(${djangoLaterVersion})`}
                    </ContactSubTitle>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <ContactTitle>CGU App:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'italic' }}>
                      {`${appUsageDate}(${appUsageVersion})`}
                    </ContactSubTitle>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <ContactTitle>Version App:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'italic' }}>
                      {notificationDevice?.metadata.version}
                    </ContactSubTitle>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <ContactTitle>Dernière connexion:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'italic' }}>
                      {user?.loggedAt && moment.format(moment.date(user?.loggedAt), 'fullDateTime24h')}
                    </ContactSubTitle>
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    {lbpRiskScoreLoading || algoanScoreLoading ? (
                      <CircularProgress />
                    ) : (
                      <ContactTitle>
                        Rating Class: {getLatestLbpRiskScoreData?.getLatestScore ?? 'N/A'} | Score Algoan:{' '}
                        {getLatestAlgoanScoreData?.getLatestScore ?? 'N/A'} | Score risque:{' '}
                        {riskScore?.score ? riskScore.score : 'aucun score trouvé'}
                      </ContactTitle>
                    )}
                  </Row>
                  <Row style={{ marginTop: 5 }}>
                    <ContactTitle>Utilisateurs parrainés:</ContactTitle>
                    <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'italic' }}>
                      {user?.sponsoredUserNbr || 0}
                    </ContactSubTitle>
                  </Row>
                  {fraudScoring && (
                    <Row style={{ marginTop: 5 }}>
                      <ContactTitle>Score fraude:</ContactTitle>
                      <ContactSubTitle style={{ marginLeft: 10, fontStyle: 'bold' }}>
                        {fraudScoring.score !== null ? fraudScoring.score : 'aucun score trouvé'}
                      </ContactSubTitle>
                    </Row>
                  )}
                  <div style={{ marginTop: 5 }}>
                    <ContactTitle style={{ fontStyle: 'italic' }}>
                      Compte {user?.active ? 'activé' : 'désactivé'}
                    </ContactTitle>
                  </div>
                  {user && <UpdateUserInfo user={user} />}
                </NameContainer>
                <div style={{ marginRight: 20, width: WIDTH < 1500 ? 400 : 800 }}>
                  <Text>Capacité d'emprunt accordée:</Text>
                  <ProgressBar
                    style={{ marginTop: 10, marginBottom: 10 }}
                    variant={user?.leftLoanCapacity < 100 ? 'danger' : 'success'}
                    now={rate}
                  />
                  <Text>
                    {user?.totalLoanCapacity > 0
                      ? `${user?.leftLoanCapacity || 0} / ${user?.totalLoanCapacity || 0} €`
                      : 'Aucune'}
                  </Text>
                  <Paper>
                    <Box padding="16px" marginTop="24px">
                      <Typography variant="h5">
                        <Box fontWeight="bold" display="inline">
                          Informations bancaires:
                        </Box>
                      </Typography>
                      <ContactSubTitle>
                        {hasEligibleTransactionsLoading ? (
                          <CircularProgress />
                        ) : (
                          <>Transaction éligible: {hasEligibleTransactions?.toString()}</>
                        )}
                      </ContactSubTitle>
                      <ContactSubTitle>
                        {user?.blockingExpiresAt && (
                          <>Time Notice: {moment.format(moment.date(user?.blockingExpiresAt), 'fullDate')}</>
                        )}
                      </ContactSubTitle>
                      {bankAccountsStatuses.map(ba => (
                        <>
                          <Grid container>
                            <Grid container>
                              <Grid container item xs={6} alignItems="center">
                                <Box display="flex">
                                  <Typography variant="p" nowRap>
                                    <Typography display="inline" style={{ fontWeight: 'bold' }}>
                                      {ba.bankName + ' '}
                                    </Typography>
                                    <Typography display="inline">{`(${ba.iban}) `}</Typography>
                                  </Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <Box display="flex" justifyContent="flex-end">
                                  <BankAccountStatus bankAccountStatus={ba} />
                                </Box>
                              </Grid>
                            </Grid>
                            <Grid>
                              <Box>
                                <Typography variant="p" style={{ fontStyle: 'italic' }}>
                                  Solde:{' '}
                                  {new Intl.NumberFormat('fr-FR', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  }).format(ba.balance)}{' '}
                                  (MAJ: {moment.date(ba.balanceUpdatedAt).format('MMM DD, YYYY - hh:mm')})
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Divider />
                        </>
                      ))}
                    </Box>
                  </Paper>
                </div>
              </ProfileInformations>
            </>
          )}
        </ProfileContainer>
      </Paper>
      <Dialog onClose={handleClose} open={opened} maxWidth={'sm'} fullWidth={true}>
        {comfirmEmail ? (
          <>
            <DialogTitle>Modification de l'email</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Veuillez indiquer au client qu'il doit confirmer son adresse mail via le lien envoyé par mail
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={handleClose}>
                OK
              </Button>
            </DialogActions>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <DialogTitle>Modification de l'email</DialogTitle>
            <DialogContent>
              <Column>
                <TextField
                  fullWidth
                  error={errors?.length}
                  helperText={errors?.length ? errors[0].message : ''}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="Email"
                  name="email"
                  placeholder="Enter Email"
                />
              </Column>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleClose}>
                ANNULER
              </Button>
              <Button type="submit" variant="contained" color="primary">
                VALIDER
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
      <Dialog onClose={handleCloseUpdateAddress} open={addressOpened} maxWidth={'sm'} fullWidth={true}>
        {confirmAddress ? (
          <>
            <DialogTitle>Modification de l'adresse</DialogTitle>
            <DialogContent>
              <DialogContentText>L'adresse a été modifiée avec succès.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" onClick={handleCloseUpdateAddress}>
                OK
              </Button>
            </DialogActions>
          </>
        ) : (
          <form onSubmit={handleSubmitAddress}>
            <DialogTitle>Modification de l'adresse</DialogTitle>
            <DialogContent>
              {errors && errors.length > 0 && (
                <div style={{ color: 'red', marginBottom: '10px' }}>
                  {errors.map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </div>
              )}

              <Column>
                <AddressAutocomplete
                  value={originalRawAddress}
                  onChange={setOriginalRawAddress}
                  placeholder="Entrer l'adresse"
                />
                <TextField
                  fullWidth
                  value={originalAdditionalAddress}
                  onChange={e => setOriginalAdditionalAddress(e.target.value)}
                  label="Complément d'adresse"
                  name="additionalAddress"
                  placeholder="Entrer le complément d'adresse"
                />
              </Column>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" color="primary" onClick={handleCloseUpdateAddress}>
                ANNULER
              </Button>
              <Button type="submit" variant="contained" color="primary">
                VALIDER
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
};

export const CommentCard = ({ comments, loading, onPageChange, totalPages }) => {
  return (
    <Paper style={{ marginTop: 30, padding: 20 }}>
      <Typography variant="h6" style={{ marginBottom: 20 }}>
        Commentaires
      </Typography>
      <CommentCardContainer loading={loading}>
        {loading ? (
          <CircularProgress />
        ) : (
          comments.map((comment, index) => (
            <CommentBox
              key={index}
              createdAt={comment.createdAt}
              content={comment.content}
              creator={comment.creator}
            />
          ))
        )}
      </CommentCardContainer>
      {totalPages === 0 ? null : (
        <Box style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>
          <Pagination count={totalPages} onChange={(e, page) => onPageChange(page)} />
        </Box>
      )}
    </Paper>
  );
};

const CommentCardContainer = ({ loading, children }) => (
  <div style={{ border: '1px solid #ddd', padding: 10 }}>{loading ? <CircularProgress /> : children}</div>
);

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};

const CommentBox = ({ createdAt, content, creator }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div
      style={{
        marginBottom: 20,
        border: '1px solid #ddd',
        padding: 10,
        display: 'flex',
        flexDirection: showFullText ? 'column' : 'row',
        alignItems: showFullText ? 'center' : 'flex-start',
        justifyContent: showFullText ? 'center' : 'space-between',
      }}
    >
      <div
        style={{ width: '100px', marginBottom: showFullText ? 10 : 0, marginRight: showFullText ? 0 : 20 }}
      >
        <span>{createdAt}</span>
      </div>
      <div style={{ cursor: 'pointer' }} onClick={toggleFullText}>
        <span>{showFullText ? content : truncateText(content, 120)}</span>
      </div>
      {!showFullText && (
        <div style={{ marginLeft: 'auto', textAlign: 'right' }}>
          <span>{truncateText(creator, 40)}</span>
        </div>
      )}
    </div>
  );
};

export const DeviceCard = ({ device, loading }) => {
  return (
    <Paper style={{ marginTop: 30 }}>
      <Text style={{ marginBottom: 20 }}>Appareil:</Text>
      <CardContainer loading={loading}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Row>
              <Title>Marque:</Title>
              <SubTitle>{device?.brand}</SubTitle>
            </Row>
            <Row>
              <Title>OS:</Title>
              <SubTitle>{device?.baseOs}</SubTitle>
            </Row>
            <Row>
              <Title>Version</Title>
              <SubTitle>{device?.readableVersion}</SubTitle>
            </Row>
          </>
        )}
      </CardContainer>
    </Paper>
  );
};

export const MissingInformationsCard = ({ user, duplicatedUser, loading }) => {
  return (
    <Paper style={{ marginTop: 30 }}>
      <Text style={{ marginBottom: 20 }}>Vérifications:</Text>
      {loading ? (
        <CardContainer loading={true}>
          <CircularProgress />
        </CardContainer>
      ) : (
        <>
          <CardContainer>
            <Row>
              <Title>Téléphone vérifié:</Title>
              <SubTitle alert={!user?.phoneVerified}>{user?.phoneVerified?.toString()}</SubTitle>
            </Row>
            <Row>
              <Title>Email vérifié:</Title>
              <SubTitle alert={!user?.emailVerified}>{user?.emailVerified?.toString()}</SubTitle>
            </Row>
            <Row>
              <Title>Identité vérifiée:</Title>
              <SubTitle alert={!user?.identityVerified}>{user?.identityVerified?.toString()}</SubTitle>
            </Row>
          </CardContainer>
          <CardContainer>
            <Row>
              <Title>Age légal:</Title>
              <SubTitle alert={!user?.ageVerified && user?.ubbleVerificationStatus === 'processed'}>
                {user?.ageVerified?.toString() || 'false'}
              </SubTitle>
            </Row>
            <Row>
              <Title>Alerte d'identité:</Title>
              <SubTitle alert={user?.identityNameAlert}>
                {user?.identityNameAlert?.toString() || 'false'}
              </SubTitle>
            </Row>
            <Row>
              <Title>Identité dupliquée:</Title>
              {user?.identityDuplication ? (
                <SubTitle alert={user?.identityDuplication}>
                  <Link to={`/dashboard/users/${duplicatedUser?.user?.id}`}>
                    {user?.identityDuplication?.toString() || 'false'}
                  </Link>
                </SubTitle>
              ) : (
                <SubTitle alert={user?.identityDuplication}>
                  {user?.identityDuplication?.toString() || 'false'}
                </SubTitle>
              )}
            </Row>
          </CardContainer>
          <CardContainer>
            <Row>
              <Title>Nombre de KYC effectué:</Title>
              <SubTitle>{user?.ubbleVerificationAttempts || '0'}</SubTitle>
            </Row>
            <Row>
              <Title>Statut identité:</Title>
              <SubTitle>{user?.ubbleVerificationStatus || 'uninitiated'}</SubTitle>
            </Row>
            <Row>
              <Title>Nombre de duplications:</Title>
              <SubTitle>{duplicatedUser?.duplicatedCount || '0'}</SubTitle>
            </Row>
          </CardContainer>
        </>
      )}
    </Paper>
  );
};

export const UbbleCard = ({ user, loading, newKyc }) => {
  if (!newKyc) return null;
  const firstName = newKyc?.customer?.civilStatus?.firstname || '';
  const lastName = newKyc?.customer?.civilStatus?.lastname || '';
  const firstNameIdentityId = newKyc?.identityDocument?.expiryDateInformation?.firstName || '';
  const lastNameIdentityId = newKyc?.identityDocument?.expiryDateInformation?.lastName || '';
  const firstNameAlert = firstName.toLowerCase() !== firstNameIdentityId.toLowerCase();
  const lastNameAlert = lastName.toLowerCase() !== lastNameIdentityId.toLowerCase();
  const documentType = newKyc?.identityDocument?.expiryDateInformation?.type;
  const errorReasonCodes = newKyc?.identityDocument?.errorReasonCodes;
  const identityStatus = newKyc?.status;
  const identityStartedAt = newKyc?.identityDocument?.startedAt;
  const identityEndedAt = newKyc?.identityDocument?.endedAt;
  const identificationId = newKyc?.identityDocument?.identificationId;
  const kycStatus = newKyc?.kycStatus;
  if (loading)
    return (
      <Paper style={{ marginTop: 30 }}>
        <CardContainer loading={true}>
          <CircularProgress />
        </CardContainer>
      </Paper>
    );
  return (
    <Paper style={{ marginTop: 30 }}>
      <Text style={{ marginBottom: 20 }}>KYC: {kycStatus}</Text>
      <CardContainer>
        <Row>
          <Title>Prénom:</Title>
          <SubTitle alert={firstNameAlert}>{firstNameIdentityId}</SubTitle>
        </Row>
        <Row>
          <Title>Nom:</Title>
          <SubTitle alert={lastNameAlert}>{lastNameIdentityId}</SubTitle>
        </Row>
        <Row>
          <Title>Type de document:</Title>
          <SubTitle>{documentType}</SubTitle>
        </Row>
      </CardContainer>
      <CardContainer>
        <Row>
          <Title>Commencé le:</Title>
          <SubTitle>
            {identityStartedAt && moment.format(moment.date(identityStartedAt), 'fullDateTime24h')}
          </SubTitle>
        </Row>
        <Row>
          <Title>Terminé le:</Title>
          <SubTitle>
            {identityEndedAt && moment.format(moment.date(identityEndedAt), 'fullDateTime24h')}
          </SubTitle>
        </Row>
        <Row>
          <Title>Statut:</Title>
          <SubTitle>{identityStatus}</SubTitle>
        </Row>
      </CardContainer>
      <CardContainer>
        <Row>
          <Title>Ubble Id:</Title>
          <SubTitle>{identificationId}</SubTitle>
        </Row>
        <Row>
          <Title>Ubble Score:</Title>
          <SubTitle>{kycStatus === 'verified' ? 1 : 0}</SubTitle>
        </Row>
        <Row />
      </CardContainer>
      {errorReasonCodes?.map((error, index) => (
        <CardContainer key={error.code}>
          <Row>
            <Box sx={{ opacity: index === 0 ? '100%' : '0%' }}>
              <Title>Code Erreur:</Title>
            </Box>
            <Typography style={{ color: 'red', fontWeight: 'bold', fontSize: '20px', marginLeft: '20px' }}>
              {error.code}
            </Typography>
            <Typography
              style={{
                fontSize: '20px',
              }}
              color="red"
            >
              [{error.reason}]
            </Typography>
          </Row>
        </CardContainer>
      ))}
    </Paper>
  );
};

export const DuplicatedBankAccountsCard = ({ duplicatedBankAccounts, onClick }) => {
  return (
    <>
      {duplicatedBankAccounts?.length ? (
        <Paper style={{ marginTop: 30 }}>
          <Text style={{ marginBottom: 20 }}>Comptes bancaires dupliqués</Text>
          {duplicatedBankAccounts.map(bankAccount => (
            <CardContainer>
              <Row>
                <Title>Nom:</Title>
                <SubTitle style={{ marginLeft: 5 }}>{bankAccount.name}</SubTitle>
              </Row>
              <Row>
                <Title>Banque:</Title>
                <SubTitle style={{ marginLeft: 5 }}>{bankAccount.bankName}</SubTitle>
              </Row>
              <Row>
                <Title>Type:</Title>
                <SubTitle style={{ marginLeft: 5 }}>{bankAccount.type}</SubTitle>
              </Row>
              {bankAccount.duplicatedOwner && (
                <Row>
                  <Title>Duplication:</Title>
                  <Link to={`/dashboard/users/${bankAccount.duplicatedOwner}`}>
                    <SubTitle style={{ marginLeft: 5 }}>
                      {bankAccount.firstname} {bankAccount.lastname}
                    </SubTitle>
                  </Link>
                </Row>
              )}
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 20 }}
                onClick={() => onClick(bankAccount.id)}
              >
                Retirer Duplication
              </Button>
            </CardContainer>
          ))}
        </Paper>
      ) : null}
    </>
  );
};
