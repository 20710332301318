import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  content: {
    paddingTop: '16px',
    paddingBottom: '16px',
    width: '100%',
  },
  action: {
    paddingTop: '16px',
    paddingBottom: '16px',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  cancelButton: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    width: '362px',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#081433',
    backgroundColor: 'transparent',
  },
  continueButton: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    width: '362px',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#fff',
    borderColor: '#FF6B4F',
    backgroundColor: '#FF6B4F',
  },
  endButton: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '22px',
    width: '362px',
    height: '52px',
    borderWidth: '1px',
    borderRadius: '16px',
    color: '#fff',
    borderColor: '#FF6B4F',
    backgroundColor: '#FF6B4F',
  },
  checkIcon: {
    color: '#FF6B4F',
    width: '50px',
    height: '50px',
    marginTop: '16px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 800,
    lineHeight: '38.4px',
    marginTop: '16px',
  },
  urlContainer: {
    width: '760px',
  },
  url: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '22px',
    whiteSpace: 'normal',
    wordBreak: 'break-word',
    maxWidth: '760px',
    width: '100%',
    minWidth: '400px',
  },
}));

const toastOptions = {
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

const PaymentLinkDialogMessage = ({ url, open, setShowDialogMessage }) => {
  const classes = useStyles();
  const dialogStyle = {
    style: {
      borderRadius: '16px',
      width: '50%',
    },
  };
  const handleCloseDialog = () => {
    setShowDialogMessage(e => !e);
  };

  const handleCopy = async () => {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(url);
    } else {
      // Fallback
      const textArea = document.createElement('textarea');
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
    toast.success('Link copied successfully!', toastOptions);
  };

  return (
    <Dialog maxWidth={'lg'} PaperProps={dialogStyle} open={open} onClose={handleCloseDialog}>
      <DialogTitle>
        <Typography variant="body1" className={classes.title}>
          <FormattedMessage id="components.stripePaymentLink.title" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          className={classes.content}
        >
          <Grid item className={classes.urlContainer}>
            <TextField
              name="paymentLink"
              className={classes.url}
              variant="outlined"
              id="custom-css-outlined-input"
              value={url}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          className={classes.action}
        >
          <Grid item>
            <button className={classes.cancelButton} onClick={handleCloseDialog}>
              <FormattedMessage id="components.stripePaymentLink.cancel" />
            </button>
          </Grid>
          <Grid item>
            <button className={classes.continueButton} onClick={handleCopy}>
              <FormattedMessage id="components.stripePaymentLink.copyLink" />
            </button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentLinkDialogMessage;
